@import 'styles/variables';

.br-business-header__wrapper {
  @page-spacing: 20px;

  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 16px;
  position: sticky;
  top: @page-spacing;
  z-index: 2;
  margin-bottom: @page-spacing;
  box-shadow: 0px 0px 0px @page-spacing @gray-50;
  background-color: @gray-50;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: @white;
    border: 1px solid @gray-200;
  }
}

.br-business-header__label-container {
  display: flex;
  align-items: center;
}

.br-business-header__label-title > div {
  > h2 {
    .display-xs();
    margin-bottom: 0;
    margin-inline-end: @spacing-md;
  }
  &,
  > span {
    .body();
    display: flex;
    align-items: center;
  }
}

.br-business-header__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: @teal-200;
  border: 1px solid @teal-200;
  border-radius: 50%;
  margin-inline-end: 12px;
}

.br-business-header__icon svg {
  width: 24px;
  height: 24px;
}

.br-business-header__icon svg path {
  stroke: @teal-500;
}

.br-business-header__label-title div {
  > span {
    color: @red-600;

    > svg {
      margin-inline-end: @spacing-xs;
      > path {
        fill: @red-600;
      }
    }
  }

  &.br-business-header__valid {
    > span {
      color: @green-600;

      > svg > path {
        fill: @green-600;
      }
    }
  }
}

.br-business-header__label-title h5 {
  color: @gray-500;
  margin: 0;
}

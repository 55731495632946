@import 'styles/variables';

article.br-business-details {
  @page-spacing: 20px;

  .br-container-content:has(> &) {
    padding-top: 0;
  }

  &, .br-business-details__management-wrapper, .br-business-details__management-wrapper > :last-child {
    display: flex;
    flex-direction: column;
    gap: @page-spacing;
  }

  .br-information-table {
    margin: 0;
  }

  .br-information-table .br-information-table__listing > .ant-descriptions-view {
    background-color: @white;
    border-radius: 16px;
    overflow: hidden;

    tr.ant-descriptions-row {
      border: none;

      > td.ant-descriptions-item-content {
        box-shadow: @border-bottom;
        width: calc(100% / 3);
        &:not(:first-child) {
          box-shadow: @border-left, @border-bottom;

          [dir="rtl"] & {
            box-shadow: @border-right, @border-bottom;
          }
        }
      }

      &:last-child > td.ant-descriptions-item-content {
        box-shadow: none;

        &:not(:first-child) {
          box-shadow: @border-left;

          [dir="rtl"] & {
            box-shadow: @border-right;
          }
        }
      }
    }

    tr.ant-descriptions-row > td.ant-descriptions-item-content {
      &:has(.br-business-details__info-tables-header, .br-table-component) {
        > span > .br-information-table__listing__field-label {
          margin: 0;
        }
      }

      &:has(.br-table-component) {
        padding: 0;

        > span > .br-information-table__listing__field-value > span.ant-typography {
          display: block;
          width: 0;
          min-width: 100%;
        }
      }

      > span {
        > .br-information-table__listing__field-label {
          .body();
          color: @gray-500;
        }

        > .br-information-table__listing__field-value > span.ant-typography {
          > .br-table-component {
            &, > .ant-table-wrapper.br-table, .ant-table.ant-table-scroll-horizontal {
              border-radius: 0;
            }

            tbody.ant-table-tbody > tr.ant-table-row {
              height: 56px;
            }
          }

          > .br-business-details__info-tables-header {
            .heading();

            display: flex;
            align-items: center;

            > svg {
              margin-inline-end: 12px;
              width: 24px;
              height: 24px;
              padding: 2px;

              & * {
                stroke: @teal-500;
              }
            }

            > button.ant-btn.br-button-component {
              margin-inline-start: auto;
            }
          }
        }
      }
    }
  }


  @media screen and (min-width: @md) {
    .br-information-table .br-information-table__listing > .ant-descriptions-view tr.ant-descriptions-row {
      display: flex;
      flex-wrap: wrap;

      > td.ant-descriptions-item-content {
        flex: 1 0 0;

        span.br-business-details__iban {
          .caption();
          color: @gray-500;
        }
      }
    }

    .br-business-details__management-wrapper {
      flex-direction: row-reverse;

      > :last-child {
        flex: 1;
      }

      > .br-business-details__settings-panel {
        min-width: 317px;

        height: 100%;

        position: sticky;
        top: @page-spacing + 100px;
      }
    }
  }
}
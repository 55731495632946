@import 'styles/variables';

.ant-tag.br-state-blue-group {
  background-color: @background-color-21;
  border-color: @background-color-21;
  color: @text-color-37;
  font-size: 14px;
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  text-align: center;
}

.ant-tag.br-state-green-group {
  background-color: @background-color-22;
  border-color: @background-color-22;
  color: @text-color-29;
  font-size: 14px;
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  text-align: center;
}

.ant-tag.br-state-purple-group {
  background-color: @background-color-23;
  border-color: @background-color-23;
  color: @text-color-31;
  font-size: 14px;
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  text-align: center;
}

.ant-tag.br-state-red-group {
  background-color: @background-color-24;
  border-color: @background-color-24;
  color: @text-color-27;
  font-size: 14px;
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  text-align: center;
}

.ant-tag.br-state-yellow-group {
  background-color: @background-color-25;
  border-color: @background-color-25;
  color: @text-color-36;
  font-size: 14px;
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  text-align: center;
}

.fulfillment-orders__container.form-group.col-sm-4 {
  height: 45px;
  width: 344px;
  border: 1px solid @border-color-24;
  font-family: @lato-font-700;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 23px 14px 14px;
}

.soho-orders__container.form-group {
  margin-bottom: 16px;
  margin-top: 27px;
  height: 38px;
  border: 1px solid @border-color-24;
  font-family: @lato-font-700;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.fulfillment-orders__flag {
  padding: 1px 10px 2px;
  border-radius: 10px;
  background-color: @background-color-64;
  color: @text-color-15;
  font-size: 13px;
  line-height: 16px;
  margin-left: 5px;
}

.deliveries-tracking-number__container {
  display: flex;
  align-items: center;
  .fulfillment-orders__flag {
    display: flex;
    align-items: center;
  }
  svg {
    margin-left: 8px;
  }
}

.br-table-mobile-card__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
}

.br-table-mobile-card__trackingNumber {
  display: inline-block;

  & .deliveries-tracking-number__container {
    display: inline-block;
  }
}

.br-table-mobile-card__title {
  display: block;
  font-size: 12px;
  color: @gray-500;
  margin-right: 4px;
}

.br-table-mobile-card__action {
  & > .anticon {
    font-size: 22px;
  }
}

.br-soho-tag.ant-tag {
  font-family: @lato-font-700;
  border-radius: 10px;
  border-color: @gray-100;
  font-size: 12px;
  margin-right: 0;
  color: @gray-800;
  background: @gray-100;
}

.br-delivery-type {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-table-mobile-card__order-type .br-delivery-type {
  flex-direction: row;
  align-items: start;
}

.br-deliveries-search__ofd-banner {
  margin-bottom: 10px;
}

.br-orders__international-order-icon {
  margin-left: 6px;

  svg {
    margin: 0;
  }
}

.br-deliveries__container {
  .ant-pagination-item {
    display: none;
  }
}

.br-deliveries__table-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.br-deliveries__bulk-actions__menu {
  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
}

.br-deliveries__print-options__menu {
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

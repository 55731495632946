@import "styles/variables";

.br-business-details__settings-panel-controls {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: -7px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: @spacing-lg;

    &:not(:last-child) {
      box-shadow: @border-bottom;
    }

    > span {
      .button-lg();

      &.disabled-switch {
        color: @gray-400;
      }
    }

    > button.ant-switch {
      min-width: 32px;
    }

    > .ant-select.br-insurance-plan__selector {
      max-width: fit-content;
      min-width: 135px;
    }
  }
}
.hubsOverview-container > * {
  width: 15%;
}

.br-table .ant-table-column-sorters {
  display: flex;
  align-items: center;
}

.br-table .ant-table-column-sorters .ant-table-column-title {
  margin-right: 8px;
}

.br-table .ant-table-column-sorters .ant-table-column-sorter {
  margin-left: 0;
  width: 60%;
}

@import 'styles/variables';

.br-geofences {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.br-geofences__hub-selectors {
  width: 550px;
}

.br-geofences__save-btn {
  max-width: 72px;
  margin-top: 16px;
}

.br-geofences__map-container {
  margin-top: 36px;
  > div > div {
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .gm-style-iw.gm-style-iw-c button:focus {
    outline: none;
  }
}

.br-geofences-info-box__container {
  background-color: @white;
  width: fit-content;
  padding: 6px;
}
